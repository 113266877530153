<!--  -->
<template>
  <div class="detail-cont">
    <div class="header">
      <div class="available-limit">
        <label>可用额度(元)</label>
        <div class="amount">{{ limitAmount }}</div>
      </div>
      <img class="material-media" src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628103557938.png" />
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="record-item" v-for="(item, index) in datalist" :key="index">
        <img class="media-icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210909103940786.png" />
        <div class="media-body">
          <van-row>
            <van-col :span="16">
              <div class="item-title" :class="{ 'line-through': [0, 3].indexOf(item.state) >= 0 }"> {{ item.title }}</div>
              <div class="order-num" :class="{ 'line-through': [0, 3].indexOf(item.state) >= 0 }"> {{ item.orderNum }}
                <span class="copy-btn" @click.stop v-clipboard:copy="item.orderNum" v-clipboard:success="copy">复制</span>
              </div>
              <div>操作时间：{{ item.startTime }}</div>
            </van-col>
            <van-col :span="8">
              <div :class="{ 'line-through': [0, 3].indexOf(item.state) >= 0 }"> <span>额度：</span>
                <span class="amount" :class="{ 'font-pink': item.posiAndnega === 1 }">
                  {{ item.posiAndnega === 1 ? '+' : '-' }}{{ item.amount }}</span>
              </div>
              <div  :class="{ 'line-through': [0, 3].indexOf(item.state) >= 0 }" v-if="item.usableAmount >= 0"> <span>可用额度(元)：</span>
                <span class="use-amount"> {{ item.usableAmount }}</span>
              </div>
              <div :class="{ 'line-through': [0, 3].indexOf(item.state) >= 0 }" v-if="item.endTime">
                有效期：{{ item.endTime.split(" ")[0] }}
              </div>
              <div :class="{ 'font-pink': item.state === 2 }">
                <template v-if="item.state === 0">已失效</template>
                <template v-if="item.state === 2">即将失效</template>
                <template v-if="item.state === 3">已取消</template>
              </div>
            </van-col>
          </van-row>


        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      limitAmount: 0,
      brandId: 3,
      loading: false,
      finished: false,
      pageIndex: 0,
      pageSize: 10,
      datalist: [],
    }
  },
  created() {
    let { bid } = this.$route.query;
    if (bid) this.brandId = +bid;
  },
  methods: {
    onLoad() {
      this.pageIndex++;
      this.get("/MaterialZone/Supplies/GetOrderLimitList", {
        brandId: this.brandId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let { buyAmonutList: list, buyAmount } = res.response;
          this.datalist = this.datalist.concat(list);
          this.limitAmount = buyAmount;
          if (list.length < this.pageSize) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      })
    },
    copy() {
      this.$toast({ message: '复制成功', icon: "success" })
    },
  }
}
</script>
<style lang='scss' scoped>
.detail-cont {
  .header {
    color: #fff;
    display: flex;
    padding: 20px 16px;
    align-items: center;
    justify-content: space-between;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628102907153.png") no-repeat;
    background-size: 100% auto;
    background-position: left bottom;

    .available-limit {

      text-align: left;

      label {
        font-size: 10px;
      }

      .amount {
        font-size: 32px;
        padding-top: 5px;
      }
    }

    .material-media {
      width: 162px;
    }
  }

  .van-list {
    .record-item {
      display: flex;
      padding: 16px;
      position: relative;

      .media-icon {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }

      .media-body {
        flex: 1;

        .van-row {
          color: #999;
          font-size: 10px;

          .van-col {
            line-height: 20px;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }

          .item-title {
            color: #666;
            font-size: 12px;
          }

          .amount {
            color: #333;
            font-size: 13px;
          }
          .use-amount{
            color: #333;
            font-size: 12px;
          }

          .order-num {
            color: #666;
          }

          .font-pink {
            color: #FFB5BB;
          }

          .copy-btn {
            color: #FF98A0;
            padding: 1px 6px;
            border-radius: 15px;
            margin-left: 10px;
            background-color: #FFF9FA;
          }
        }

        .line-through {
          text-decoration: line-through;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 46px;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #F1F1F1;
      }
    }
  }
}
</style>